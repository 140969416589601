import Swiper from 'swiper/bundle';

export default () => {
  sliderInit();
  translator();

  function sliderInit() {
    const sliderPrefix = 'stat__';
    let sliderEl = document.querySelector(`.${sliderPrefix}slider`);

    if (sliderEl) {
      let slider = new Swiper(sliderEl, {
        wrapperClass: `${sliderPrefix}slider-wrapper`,
        slideClass: `${sliderPrefix}slide`,
        slideActiveClass: `${sliderPrefix}slide--active`,
        
        slidesPerView: 1,
        speed: 300,
        spaceBetween: 24,
        pagination: {
          el: '.stat__slider-pagination',
          type: 'bullets',
          bulletClass: 'stat__slider-bullet',
          bulletActiveClass: 'stat__slider-bullet--active',
          clickable: true
        },
        grabCursor: true,
        
        breakpoints: {
          768: {
            slidesPerView: 'auto',
          },
          
        },
      });
    }
  }

  function translator() {
    let input = document.querySelector('.widget__translate-input');
    let output = document.querySelector('.widget__translate-output');

    if (input && output) {
      input.addEventListener('input', (e) => {
        output.innerText = input.value;
      });
    }
  }
}
