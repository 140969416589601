export default () => {
  let speakers = document.querySelector('.speakers');
  let btn = document.querySelector('.speakers__btn');
  let label = document.querySelector('.speakers__btn-label');
  let expandedClass = 'speakers--expanded'


  if (btn && speakers) {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      let isActive = speakers.classList.toggle(expandedClass);
      if (isActive) {
        label.innerText = label.dataset.textCollapse;
      } else {
        label.innerText = label.dataset.textShow;
      }
      
    });
  }
}
