import screenfull from 'screenfull';
import detectWebGl from '../helpers/detect-webgl';

export default () => {
  let portalBtn = document.querySelector('.js-open-popup');
  let portalCloseBtn = document.querySelector('.js-close-popup');
  let notice = document.querySelector('.portal-notice');
  let popup = document.querySelector('.portal-popup');
  const visibleClass = 'portal-popup--visible'
  let doc = document.documentElement;
  let isWglSupported = detectWebGl();

  if (portalBtn && notice && popup) {
    portalBtn.addEventListener('click', (e) => {
      e.preventDefault();

      if (isWglSupported) {
        popup.classList.add(visibleClass);

        if (screenfull.isEnabled) {
          screenfull.request();
        } else {
          
        }
      } else {
        notice.classList.add('portal-notice--active');
      }
    });

    portalCloseBtn.addEventListener('click', (e) => {
      e.preventDefault();

      popup.classList.remove(visibleClass);
      screenfull.exit();
    });
  }
}
