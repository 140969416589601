import gsap from "gsap";

export default () => {
  let menu = document.querySelector('.header__menu');
  let menuButton = document.querySelector('.header__menu-btn');
  let list  = document.querySelector('.header__menu-list');

  const activeClass = 'header__menu--expanded';

  if (menu && menuButton) {
    menuButton.addEventListener('click', (e) => {
      setMenu(-1);
    });


    document.addEventListener('click', (e) => {
      if (!e.target.closest('.header__menu')) {
        setMenu(false);
      }
    });
  }
  

  function setMenu(state) {
    let isActive;

    if (state == -1) {
      isActive = menu.classList.toggle(activeClass);
    } else if (state == true) {
      isActive = menu.classList.add(activeClass);
    } else {
      isActive = menu.classList.remove(activeClass);
    }

    gsap.to(list, {
      height: isActive ? 'auto' : 0,
      duration: 0.3
    });
  }
}
