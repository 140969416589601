export default () => {
  if (typeof WebGLRenderingContext === 'undefined') {
    return 0;
  } else {
    let gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return 0;
    } else {
      return 1;
    }
  }
}