export default () => {
  let blocks = document.querySelectorAll('.sessions');

  if (blocks.length) {
    blocks.forEach(function(block) {
      let btn = block.querySelector('.speakers__btn');
      let label = block.querySelector('.speakers__btn-label');
      let expandedClass = 'sessions--expanded'


      if (btn) {
        btn.addEventListener('click', (e) => {
          e.preventDefault();

          let isActive = block.classList.toggle(expandedClass);
          if (isActive) {
            label.innerText = label.dataset.textCollapse;
          } else {
            label.innerText = label.dataset.textShow;
          }
          
        });
      }
    });
  }
}
