import header from './components/header';
import stat from './components/stat';
import portal from './pages/portal';
import speakers from './pages/speakers';
import sessions from './pages/sessions';


export default () => {
  header();
  stat();

  portal();
  speakers();
  sessions();
}